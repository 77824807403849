import { headers } from "@core/utils/utils";
export default {
  namespaced: true,
  state: () => ({
    agente: {
      id: null,
      name: "",
      display_name: "",
      description: "",
      default_language: "es",
      region: "global",
      time_zone: "America/Chicago",
      avatar_uri: null,
      enable_spell_correction: false,
      enable_multi_language_training: false,
      locked: false,
      concierge_id:null,
    },

    agentes: [],
  }),

  getters: {
    draft: (state) => clone(state.agente),
  },

  mutations: {
    clear(state) {
      state.agente = {
        id: null,
        name: "",
        display_name: "",
        description: "",
        default_language: "es",
        region: "global",
        time_zone: "America/Chicago",
        avatar_uri: null,

        enable_spell_correction: false,
        enable_multi_language_training: false,
        locked: false,
        concierge_id:null,
      };
    },

    setAgentes(state, agentes) {
      if (agentes) {
        state.agentes = agentes;
      }
    },

    setAgente(state, agente) {
      if (agente) {
        state.agente = agente;
      }
    },
  },

  actions: {
    fetch({ commit }, agente_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/agents/${agente_id}/fetch-data`)
          .then(({ data }) => {
            commit("setAgente", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchData({ commit, dispatch }, datos) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/agents/fetch-data`, datos)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => reject(e));
      });
    },

    guardar({ commit }, datos) {
      return new Promise((resolve, reject) => {
        if (datos.id) {
          axios
            .put(`/api/agents/${datos.id}`, datos)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        } else {
          axios
            .post(`/api/agents`, datos)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        }
      });
    },

    eliminar({ state, commit }, agente_id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/agents/${agente_id}`)
          .then(({ data }) => resolve(data))
          .catch((e) => reject(e));
      });
    },
  },
};
