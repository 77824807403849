import { headers } from "../../@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
    
    concierge:{
      id          : null,
      nombre      : '',
      url         : '',
      activo      : false,
      configurado : false,
      observacion : '',
      logo        : null,
      concierge_qr: '',
      cliente_id  : null,
      compra_id   : null,
      medio_id    : null,
      cliente     : null,
      compra      : null,
      medio       : null,
      color_primario_id:null,
      color_secundario_id:null,
      color_primario:null,
      color_secundario:null,
    },

    preview:{
      nombre     : '',
      url        : '',
      activo     : false,
      configurado: false,
      observacion: '',
      logo       : null,
      logo_url   : null,
      menus:[],
      color_primario_id:null,
      color_secundario_id:null,
      color_primario:null,
      color_secundario:null,
    },

    concierges:[],
    concierges_publicos: []
  }),

  getters:{
    draft:(state) => clone(state.concierge),
    nombreConcierge:(state) => state.concierge.nombre,
  },



  mutations:{

    clear(state){
      state.concierge = {
          id          : null,
          nombre      : '',
          url         : '',
          activo      : false,
          configurado : false,
          observacion : '',
          logo        : null,
          
          concierge_qr: '',
          cliente_id  : null,
          compra_id   : null,
          medio_id    : null,
          cliente     : null,
          compra      : null,
          medio       : null,
          color_primario_id:null,
          color_secundario_id:null,
          color_primario:null,
          color_secundario:null,
      }
    },


    setConcierges(state,concierges){
      if(concierges){
        state.concierges = concierges
      }
    },


    setConcierge(state,concierge){
      if(concierge){
        state.concierge = concierge
        state.preview = clone(concierge)
      }
    },

    setPreview(state,preview_concierge){
      state.preview = preview_concierge
    },


    update(state,concierge){
      let i = state.concierges.findIndex(c => c.id == concierge.id)

      if(i != -1){
        state.concierges[i] = concierge
      }

      if(state.concierge.id == concierge.id){
        state.concierge = concierge
      }
    },

    setConciergesPublicos(state,concierges){
      state.concierges_publicos = concierges
    }
  },


  actions:{

    fetch({commit},concierge_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/${concierge_id}/fetch-data`).then(({data}) => {
          commit('setConcierge',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchPorUrl({commit},url){
      return new Promise((resolve, reject) => {
        axios.post(`/api/concierges/fetch-por-url`,{url}).then(({data}) => {
          commit('setConcierge',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/concierges/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();
      Object.keys(datos).forEach(key => {
        if(datos[key] != null){
          formData.append(key,datos[key])
        }
      });
      
      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
           axios.post(`/api/concierges/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.concierge)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
           axios.post(`/api/concierges`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},concierge_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/concierges/${concierge_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getconciergesPorCliente({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/cliente/${cliente_id}/get-all`).then(({data}) => {
          commit('setConcierges',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getconciergesActivosPorCliente({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/activos/cliente/${cliente_id}/get-all`).then(({data}) => {
          commit('setConcierges',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchconciergePorNombre({commit},nombre){
      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/nombre/${nombre}`).then(({data}) => {
          commit('setConcierge',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    asignarMedio({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/concierges/${datos.concierge_id}/asignar-medio`,{medio_id:datos.medio_id}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardarObservacion({commit},{id,observacion}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/concierges/${id}/set-observacion`,{observacion}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getDiskNameCliente({commit},concierge_id){
      
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/concierges/${concierge_id}/get/disk-name-client`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e));

      })

    },

    getListConciergesPublicos({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/get-list-concierges-publicos`).then(({data}) => {
          commit("setConciergesPublicos", data);
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getDataLight({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/concierges/get-data-light`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }

}